<template>
    <div class="doc_api">
      <h2>Formulario Firma On Device</h2>
      <table>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Tipo de Dato</th>
            <th>Ejemplo de Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.name">
            <td>{{ field.name }}</td>
            <td>{{ field.type }}</td>
            <td>{{ field.example }}</td>
          </tr>
        </tbody>
      </table>
      <pre>{{ jsonExample }}</pre>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
          { name: 'evento_id', type: 'Integer', example: 123 },
          { name: 'evento_id_asi', type: 'Integer', example: 124 },
          { name: 'evento_name_asi', type: 'String', example: 'Seminario de Actualización' },
          { name: 'datef', type: 'Date', example: '2023-12-15' },
          { name: 'firma_asi', type: 'String (base64 encoded image)', example: 'data:image/png;base64,...' },
          { name: 'nombre_asi', type: 'String', example: 'Dr. Juan Pérez' },
          { name: 'usuario_id_asi', type: 'Integer', example: 456 },
          { name: 'usuario_asi', type: 'String', example: 'jperez' },
          { name: 'tipo_asi', type: 'String', example: 'Principal' },
          { name: 'codigo_veeva_taller', type: 'String', example: 'V12345' }
        ],
        jsonExample: JSON.stringify({
          evento_id: 123,
          evento_id_asi: 124,
          evento_name_asi: 'Seminario de Actualización',
          datef: '2023-12-15',
          firma_asi: 'data:image/png;base64,...',
          nombre_asi: 'Dr. Juan Pérez',
          usuario_id_asi: 456,
          usuario_asi: 'jperez',
          tipo_asi: 'Principal',
          codigo_veeva_taller: 'V12345'
        }, null, 2)
      };
    }
  }
  </script>
  