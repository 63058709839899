<template>
    <div class="doc_api">
      <h2>Formulario Encuesta</h2>
      <table>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Tipo de Dato</th>
            <th>Ejemplo de Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.name">
            <td>{{ field.name }}</td>
            <td>{{ field.type }}</td>
            <td>{{ field.example }}</td>
          </tr>
        </tbody>
      </table>
      <pre>{{ jsonExample }}</pre>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
          { name: 'userid', type: 'Integer', example: 101 },
          { name: 'username', type: 'String', example: 'usuario123' },
          { name: 'evento_id', type: 'Integer', example: 102 },
          { name: 'event_name', type: 'String', example: 'Conferencia Internacional de Medicina' },
          { name: 'calificacion', type: 'String', example: 'Excelente' },
          { name: 'message', type: 'String', example: 'Muy informativa y bien organizada.' }
        ],
        jsonExample: JSON.stringify({
          userid: 101,
          username: 'usuario123',
          evento_id: 102,
          event_name: 'Conferencia Internacional de Medicina',
          calificacion: 'Excelente',
          message: 'Muy informativa y bien organizada.'
        }, null, 2)
      };
    }
  }
  </script>
  