<template>
    <div class="doc_api">
      <h2>Formulario Declaración Paciente</h2>
      <table>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Tipo de Dato</th>
            <th>Ejemplo de Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.name">
            <td>{{ field.name }}</td>
            <td>{{ field.type }}</td>
            <td>{{ field.example }}</td>
          </tr>
        </tbody>
      </table>
      <pre>{{ jsonExample }}</pre>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
          { name: 'entendimiento_resultados', type: 'String', example: 'Sí' },
          { name: 'entendimiento_efectos', type: 'String', example: 'Sí' },
          { name: 'nombre', type: 'String', example: 'Juan Pérez' },
          { name: 'edad', type: 'Integer', example: 30 },
          { name: 'telefono', type: 'String', example: '555-1234' },
          { name: 'correo', type: 'String', example: 'juan@example.com' },
          { name: 'inyector', type: 'String', example: 'Dr. Smith' },
          { name: 'fecha', type: 'Date', example: '2023-01-01' },
          { name: 'nombre_contacto', type: 'String', example: 'Ana López' },
          { name: 'telefono_contacto', type: 'String', example: '555-5678' },
          { name: 'mirrored_nombre', type: 'String', example: 'Juan Pérez' },
          { name: 'firma', type: 'String (base64 encoded image)', example: 'data:image/png;base64,...' },
          { name: 'mirrored_fecha', type: 'Date', example: '2023-01-01' },
          { name: 'evento_id', type: 'Integer', example: 1 }
        ],
        jsonExample: JSON.stringify({
          entendimiento_resultados: 'Sí',
          entendimiento_efectos: 'Sí',
          nombre: 'Juan Pérez',
          edad: 30,
          telefono: '555-1234',
          correo: 'juan@example.com',
          inyector: 'Dr. Smith',
          fecha: '2023-01-01',
          nombre_contacto: 'Ana López',
          telefono_contacto: '555-5678',
          mirrored_nombre: 'Juan Pérez',
          firma: 'data:image/png;base64,...',
          mirrored_fecha: '2023-01-01',
          evento_id: 1
        }, null, 2)
      };
    }
  }
  </script>
  