<template>
    <div class="doc_api">
      <h2>Formulario Toma de Evidencia</h2>
      <table>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Tipo de Dato</th>
            <th>Ejemplo de Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.name">
            <td>{{ field.name }}</td>
            <td>{{ field.type }}</td>
            <td>{{ field.example }}</td>
          </tr>
        </tbody>
      </table>
      <pre>{{ jsonExample }}</pre>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
            { name: 'event_name', type: 'String', example: 'Conferencia Médica 2023' },
            { name: 'timestamp', type: 'String', example: '2023-10-05T14:48:00.000Z' },
            { name: 'usernamef', type: 'String', example: 'usuario123' },
            { name: 'fotoevidencia', type: 'String (URL)', example: 'http://example.com/evidencia.jpg' },
            { name: 'evento_id', type: 'Integer', example: 101 }
        ],
        jsonExample: JSON.stringify({
            event_name: 'Conferencia Médica 2023',
            timestamp: '2023-10-05T14:48:00.000Z',
            usernamef: 'usuario123',
            fotoevidencia: 'http://example.com/evidencia.jpg',
            evento_id: 101
        }, null, 2)
      };
    }
  }
  </script>
  