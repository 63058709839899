<template>
    <div class="doc_api">
      <h2>Formulario Speaker Injector</h2>
      <table>
        <thead>
          <tr>
            <th>Campo</th>
            <th>Tipo de Dato</th>
            <th>Ejemplo de Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in fields" :key="field.name">
            <td>{{ field.name }}</td>
            <td>{{ field.type }}</td>
            <td>{{ field.example }}</td>
          </tr>
        </tbody>
      </table>
      <pre>{{ jsonExample }}</pre>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        fields: [
          { name: 'nombre', type: 'String', example: 'Dr. Ana Torres' },
          { name: 'cedula_prof', type: 'String', example: '1234567890' },
          { name: 'correo', type: 'String', example: 'ana.torres@example.com' },
          { name: 'event_name', type: 'String', example: 'Conferencia de Salud' },
          { name: 'fecha', type: 'Date', example: '2023-12-25' },
          { name: 'mirrored_nombre', type: 'String', example: 'Dr. Ana Torres' },
          { name: 'firma', type: 'String (base64 encoded image)', example: 'data:image/png;base64,...' },
          { name: 'mirrored_fecha', type: 'Date', example: '2023-12-25' },
          { name: 'evento_id', type: 'Integer', example: 1 }
        ],
        jsonExample: JSON.stringify({
          nombre: 'Dr. Ana Torres',
          cedula_prof: '1234567890',
          correo: 'ana.torres@example.com',
          event_name: 'Conferencia de Salud',
          fecha: '2023-12-25',
          mirrored_nombre: 'Dr. Ana Torres',
          firma: 'data:image/png;base64,...',
          mirrored_fecha: '2023-12-25',
          evento_id: 1
        }, null, 2)
      };
    }
  }
  </script>
  