<template>
    <div>
      <h1>Documentación API para Formularios</h1>
      <DeclaracionPacienteDoc/>
      <SpeakerInjectorDoc />
      <TomaEvidenciaDoc  />
      <FirmaOnDeviceDoc  />
      <EncuestaDoc  />
      <!-- Aquí irán más subcomponentes conforme se agreguen -->
    </div>
  </template>
  
  <script>
  import DeclaracionPacienteDoc from './elementos/DeclaracionPacienteDoc.vue';
  import SpeakerInjectorDoc  from './elementos/SpeakerInjectorDoc.vue';
  import TomaEvidenciaDoc  from './elementos/TomaEvidenciaDoc.vue';
  import FirmaOnDeviceDoc  from './elementos/FirmaOnDeviceDoc.vue';
  import EncuestaDoc  from './elementos/EncuestaDoc.vue';
  
  export default {
    components: {
      DeclaracionPacienteDoc,
      SpeakerInjectorDoc, 
      TomaEvidenciaDoc, 
      FirmaOnDeviceDoc, 
      EncuestaDoc, 
    }
  }
  </script>
  <style lang="sass">
  .doc_api
    margin: 20px
    padding: 20px
    background-color: #f9f9f9
    border: 1px solid #ddd
    border-radius: 8px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  
    h2
      color: #333
      font-size: 24px
  
    table
      width: 100%
      margin-top: 20px
      border-collapse: collapse
  
      th, td
        padding: 8px
        text-align: left
        border-bottom: 1px solid #ddd
  
      th
        background-color: #f2f2f2
  
    pre
      margin-top: 20px
      padding: 15px
      background-color: #eef
      border: solid 1px #ccd
      border-radius: 5px
      overflow-x: auto
      white-space: pre-wrap
      word-wrap: break-word
      font-size: 16px
      color: #036
      text-align: left
  </style>